export class apiList {
  login = "login";
  addOns = "addOns";
  group = "group";
  menus = "menus";
  item = "item";
  foodItems = "foodItems";
  itemWiseGrp = "itemWiseGrp";
  orders = "orders";
  category = "category";
  branch = "branch";
  forgotpassword = "forgotPassword";
  verifyotp = "verifyOtp";
  setpassword = "setPassword";
  cms = "cms";
  changepassword = "changePassword";
  updateprofile = "updateProfile";
  profile = "profile";
  faq = "faq";
  restaurantDocuments = "restaurantDocuments";
  discount = "discount";
  subAdmin = "subAdmin";
  reviews = "rating";
  viewReview = "rating";
  revert = "restReply";
  revenue = "revenue";
  inventory = "inventory";
  changeQunatity = "changeQty";
  status = "itemStatus";
  menuAvailability = "item";
  dashboard = "dashboard";
  exportbranch = "exportBranch";
  exportrevenue = "exportRevenue";
  onlineOffline = "onlineOffline";
  userDashboard = "userDashboard";
  orderDashboard = "orderDashboard";
  orderMail = "orderMail";
  comment = "comment";
  itemAvaliable = "itemAvaliable";
  addOnsAvaliable = "addOnsAvaliable";
  disAvaliable = "disAvaliable";
  subAdminStatus = "subAdminStatus";
  timing = "timing";
  availableStatus = "avaliableStatus";
  orderMailDownload = "orderMailDownload";
  categoryIndex = "categoryIndex";
}
