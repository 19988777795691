import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { Router } from "@angular/router";
import { HttpService } from "@shared/services/http.service";
import { CommonService } from "@shared/services/common.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  loginForm: FormGroup;
  processing = false;
  errorMsg = null;
  isSubmitted: boolean = false;
  isRemember: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["lovely@yopmail.com", [Validators.required, Validators.email]],
      password: ["Pass@123", Validators.required],
    });
    if (sessionStorage.userCred || sessionStorage.userPass) {
      this.isRemember = true;
      this.loginForm.controls["email"].setValue(sessionStorage.userCred);
      this.loginForm.controls["password"].setValue(sessionStorage.userPass);
      this.loginForm.controls["email"].updateValueAndValidity();
      this.loginForm.controls["password"].updateValueAndValidity();
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.loginForm.markAllAsTouched();
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    } else {
      this.http.httpPostWithoutHeader("login", this.loginForm.value).subscribe(
        (res: any) => {
          if (res.status == 200) {
            localStorage.setItem("User", JSON.stringify(res.data));
            localStorage.setItem("Authorization", res.data.token);

            this.router.navigate(["/booking/booking"]);
            // this.loginForm.reset();
            this.isSubmitted = false;
          } else {
            this.commonService.presentsToast("error", res.message);
          }
        },
        () => {}
      );
    }
  }

  get f(): any {
    return this.loginForm.controls;
  }

  onRememberCheck(event: any) {
    if (
      event.target.checked &&
      !(
        this.loginForm.value.email == "" ||
        this.loginForm.value.email == undefined ||
        (this.loginForm.value.email == "null" &&
          this.loginForm.value.password == "") ||
        this.loginForm.value.password == undefined ||
        this.loginForm.value.password == "null"
      )
    ) {
      sessionStorage.setItem("userCred", this.loginForm.value.email);
      sessionStorage.setItem("userPass", this.loginForm.value.password);

      this.isRemember = true;
    } else if (event.target.checked == false) {
      this.isRemember = false;
      sessionStorage.removeItem("userCred");
      sessionStorage.removeItem("userPass");
    }
  }
}
