<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <!-- <div class="Auth__page-title-pre">WELCOME TO</div> -->
      <div class="Auth__page-title mat-display-3">Change Password</div>
    </div>
    <div class="Auth__form-container">
      <form class="AuthForm" [formGroup]="changepasswordForm">
        <div class="form-group">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="password"
              placeholder="Password"
            />
            <mat-error
              *ngIf="
                isSubmitted &&
                changepasswordForm.controls['password'].hasError('required')
              "
              >Password is required</mat-error
            >
            <mat-error
              *ngIf="
                isSubmitted &&
                changepasswordForm.controls['password'].hasError('minlength')
              "
              >Password should be of minimum 8 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label> Confirm Password</mat-label>
            <input
              matInput
              type="password"
              formControlName="confirmpassword"
              placeholder=" Confirm Password"
            />
            <mat-error
              *ngIf="
                isSubmitted &&
                changepasswordForm.controls['confirmpassword'].hasError(
                  'required'
                )
              "
            >
              Confirm Password is required</mat-error
            >
            <mat-error
              *ngIf="
                isSubmitted &&
                changepasswordForm.controls['confirmpassword'].hasError(
                  'mustMatch'
                )
              "
            >
              Confirm Password must match
            </mat-error>
          </mat-form-field>
        </div>
        <button
          mat-flat-button
          type="submit"
          (click)="updatePwd()"
          color="primary"
          class="mt-2"
        >
          Submit
          <!-- {{ processing ? "LOGGING IN..." : "LOG IN" }} -->
        </button>
        <p routerLink="/auth" class="loginpara">Back to Login</p>
      </form>
    </div>
  </div>
</div>
