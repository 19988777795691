import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// MODULES
import { SharedModule } from "@shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AgmCoreModule } from "@agm/core";
import { AppRoutingModule } from "./app-routing.module";

// PROVIDERS
import { AuthGuard } from "@shared/guards/auth.guard";
import { CsvExporter } from "@shared/services/csv-exporter.service";
// import { AudioService } from "@shared/services/audio.service";

// COMPONENTS
import { AppComponent } from "./app.component";
import { AuthComponent } from "./auth/auth.component";
//  import { NgxEchartsModule } from 'ngx-echarts';

// CLASSES

// export function initAppConfig(appService: AppService) {
//   return () => appService.load();
// }
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { ForgotComponent } from "./auth/forgot/forgot.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { apiList } from "@shared/services/api-list";
import { HttpService } from "@shared/services/http.service";
import { InterceptorService } from "@shared/services/interceptor.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { OtpComponent } from "./auth/otp/otp.component";
import { ChangepasswordComponent } from "./auth/changepassword/changepassword.component";
import { MatPaginatorModule } from "@angular/material/paginator";
//  import { NgxEchartsModule } from "ngx-echarts";
import { EChartsOption } from "echarts";

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ForgotComponent,
    OtpComponent,
    ChangepasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot(),
    SharedModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgxDaterangepickerMd,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    FormsModule,
    // NgxEchartsModule.forRoot({
    //   /**
    //    * This will import all modules from echarts.
    //    * If you only need custom modules,
    //    * please refer to [Custom Build] section.
    //    */
    //   echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    // }),
  ],

  providers: [
    CsvExporter,
    // AudioService,
    AuthGuard,

    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    apiList,
    HttpService,
  ],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
