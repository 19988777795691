<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <!-- <div class="Auth__page-title-pre">WELCOME TO</div> -->
      <div class="Auth__page-title mat-display-3">Welcome to Apptunix</div>
    </div>
    <div class="Auth__form-container">
      <div class="Auth__form-title mat-display-1 text-center">
        Log In
      </div>
      <form class="AuthForm" [formGroup]="loginForm" (submit)="onSubmit()">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-error *ngIf="isSubmitted && f.email.errors?.required">
            Email is required
          </mat-error>
          <mat-error *ngIf="isSubmitted && f.email.errors?.email">
            Enter valid email
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Password"
            required
          />
          <mat-error *ngIf="isSubmitted && f.password.errors?.required">
            Password is required
          </mat-error>
        </mat-form-field>
        <!-- <div class="checkbox checkbox-primary pull-left p-t-0 check-align">
          <input
            id="checkbox-signup"
            [checked]="isRemember"
            (change)="onRememberCheck($event)"
            type="checkbox"
          />
          <label for="checkbox-signup " class="ml-2"> Remember me </label>
        </div> -->
        <!-- <input class="example-margin" [checked]="isRemember" (change)="onRememberCheck($event)" value="Remember Me"> -->
        <button
          mat-flat-button
          type="submit"
          color="primary"
          routerLinkActive="router-link-active"
          class="mt-2"
        >
          LOG IN
        </button>
        <p routerLink="/forgot" class="loginpara">Forgot Password</p>
      </form>
    </div>
  </div>
</div>
