import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private spinner: NgxSpinnerService) {}
  addOns: BehaviorSubject<any> = new BehaviorSubject([]);
  groups: BehaviorSubject<any> = new BehaviorSubject([]);
  resId: BehaviorSubject<any> = new BehaviorSubject("");
  catId: BehaviorSubject<any> = new BehaviorSubject("");

  presentsToast(type: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  confirmToast() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        return true;
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  openDialog(type, message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    });
  }

  hideSpinner() {
    console.log("hide spinner");

    this.spinner.hide();
  }
  showSpinner() {
    console.log("show spinner");
    this.spinner.show();
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
