import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { apiList } from "./api-list";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import * as io from "socket.io-client";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseUrl: any = environment.baseUrl;
  token: string;
  user: any;
  socket: any;
  constructor(private http: HttpClient, private apiList: apiList) {}

  httpPostWithoutHeader(url: any, param: any) {
    return this.http.post(this.baseUrl + this.apiList[url], param);
  }
  httpPutWithoutHeader(url: any, id: any, param: any) {
    return this.http.put(this.baseUrl + this.apiList[url] + id + "/", param);
  }
  httpGetWithoutHeaderWithParam(url: any, param: any, formData: any) {
    var Q = param ? "/" : "";
    return this.http.get(
      this.baseUrl + this.apiList[url] + param + "/",
      formData
    );
  }
  httpPostWithHeader(url: any) {
    return this.http.post(this.baseUrl + this.apiList[url], this.header());
  }

  httpPostWithHeaderAndBody(url: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.header()
    );
  }

  httpPostWithHeaderAndBodyWithId(url: any, id: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.header()
    );
  }

  httpPostWithFormDataHeaderAndBody(url: any, param: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      param,
      this.formDataHeader()
    );
  }

  httpPutWithFormDataHeaderAndBodyWithid(url: any, id: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.formDataHeader()
    );
  }

  httpPutWithDataHeaderAndBodyWithid(url: any, id: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url] + id + "/",
      param,
      this.header()
    );
  }

  httpPutWithDataHeaderAndBody(url: any, param: any) {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      param,
      this.header()
    );
  }

  httpGetWithHeaderAndId(url: any, id: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url] + id + "/",
      this.header()
    );
  }

  httpGetWithHeaderAndQuery(url: any, query: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "?" + query,
      this.header()
    );
  }

  httpGetWithHeaderAndParams(url: any, param: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "/" + param,
      this.header()
    );
  }

  httpGetWithHeaderAndParamsandquery(url: any, param: any, query: any) {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "/" + param + "?" + query,
      this.header()
    );
  }

  httpGetWithHeaderOnly(url: any) {
    return this.http.get(this.baseUrl + this.apiList[url], this.header());
  }

  httpDeleteHeader(url: string, params: any) {
    let q = params ? "/" : "";
    return this.http.delete(
      this.baseUrl + this.apiList[url] + params + "/",
      this.header()
    );
  }
  httpDeleteHeaderWithId(url: string, id: any) {
    let q = id ? "/" : "";
    return this.http.delete(
      this.baseUrl + this.apiList[url] + "/" + id,
      this.header()
    );
  }

  header() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        "content-type": "application/json",
        // Accept: 'application/json',

        Authorization: "Bearer " + localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  formDataHeader() {
    if (
      localStorage.Authorization != undefined ||
      localStorage.Authorization != null
    ) {
      const headers = new HttpHeaders({
        Authorization: "Bearer " + localStorage.Authorization,
      });
      const option = {
        headers,
      };
      return option;
    }
  }
  connection() {
    console.log("geterer");
    this.token = localStorage.getItem("Authorization");
    console.log(localStorage.getItem("User"), "locla user");

    if (localStorage.getItem("User")) {
      this.user = JSON.parse(localStorage.getItem("User"));
      console.log("SDfsdfd");
      this.socket = io.connect(environment.socket_url, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: { token: this.token },
        transports: ["websocket", "polling", "flashsocket"],
      });
      console.log(this.socket, "socket");

      // this.socket.on('connect', res => {
      //   const data = {
      //     userId: 12
      //   };
      //   this.socket.emit('check', data);
      // });
    }
  }

  getUserNotificationListener() {
    return Observable.create((observer) => {
      this.socket.removeListener("check");
      this.socket.on("check", (message) => {
        console.log("order", message);
        observer.next(message);
      });
    });
  }

  getOrderStatusChangeListener() {
    return Observable.create((observer) => {
      this.socket.removeListener("UpdaterestStatus");
      this.socket.on("UpdaterestStatus", (message) => {
        console.log("UpdaterestStatus", message);
        observer.next(message);
      });
    });
  }
  OrderNotFoundSoundClose() {
    return Observable.create((observer) => {
      this.socket.removeListener("OrderNotFoundSoundClose");
      this.socket.on("OrderNotFoundSoundClose", (message) => {
        console.log("OrderNotFoundSoundClose", message);
        observer.next(message);
      });
    });
  }
}
