<div class="Auth">
  <div class="Auth__inner">
    <div class="Auth__title-container">
      <!-- <div class="Auth__page-title-pre">WELCOME TO</div> -->
      <div class="Auth__page-title mat-display-3 mb-2">
        <img
          style="width: 100px"
          src="assets/img/png/dineinlogo.png"
          alt="logo"
        />
      </div>

      <div class="Auth__page-title mat-display-3">Forgot Password</div>
    </div>
    <div class="Auth__form-container">
      <!-- <div class="Auth__form-title mat-display-1 text-center">
       Forgot Password
      </div> -->
      <form class="AuthForm" [formGroup]="forgotPwdForm">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" placeholder="Email" />

          <mat-error
            *ngIf="
              isSubmitted &&
              forgotPwdForm.controls['email'].hasError('required')
            "
            >Email is required</mat-error
          >
          <mat-error
            *ngIf="
              isSubmitted && forgotPwdForm.controls['email'].hasError('pattern')
            "
          >
            Valid Email is required</mat-error
          >
        </mat-form-field>

        <button
          mat-flat-button
          type="submit"
          (click)="forgotPwd()"
          color="primary"
          class="mt-2"
        >
          Submit
          <!-- {{ processing ? "LOGGING IN..." : "LOG IN" }} -->
        </button>
        <p routerLink="/auth" class="loginpara">Back to Login</p>
      </form>
    </div>
  </div>
</div>
