<router-outlet>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white;">Loading...</p>
  </ngx-spinner>
  <!-- <div class="chat_fixed" *ngIf="showChat">
    <a href="https://dineinhelp.zendesk.com/agent/dashboard" target="_blank">
      <i class="fa fa-comment" aria-hidden="true"> </i
    ></a>
  </div> -->
</router-outlet>
