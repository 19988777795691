import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { Router } from "@angular/router";
import { HttpService } from "@shared/services/http.service";
import { CommonService } from "@shared/services/common.service";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  forgotPwdForm: FormGroup;
  processing = false;
  isSubmitted: boolean = false;
  errorMsg = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpService,
    private _commService: CommonService
  ) {}

  ngOnInit(): void {
    //   this.loginForm = this.fb.group({
    //     email: ["", Validators.required],
    //     password: ["", Validators.required],
    //   });
    // }
    this.forgotPwdForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
    });
  }

  // onSubmit() {
  //   this.loginForm.markAllAsTouched();
  //   this.loginForm.markAllAsTouched();
  //   if (this.loginForm.invalid) {
  //     return;
  //   }

  //   this.processing = true;
  //   this.authMutation
  //     .login(this.loginForm.value)
  //     .then((res) => {
  //       this.keycloakAuthService.startSession(
  //         res.data.service_login_backoffice_user
  //       );
  //       this.router
  //         .navigateByUrl("dashboard")
  //         .then(() => this.loginForm.reset())
  //         .finally(() => (this.processing = false));
  //     })
  //     .catch((res) => {
  //       this.processing = false;
  //       this.errorMsg = "Invalid Email or Password";
  //     });
  // }

  // get f(): any {
  //   return this.loginForm.controls;
  // }
  forgotPwd() {
    this.isSubmitted = true;
    if (this.isSubmitted && this.forgotPwdForm.valid) {
      this.http
        .httpPostWithHeaderAndBody("forgotpassword", this.forgotPwdForm.value)
        .subscribe(
          (response: any) => {
            localStorage.setItem("email", this.forgotPwdForm.value.email);

            localStorage.setItem("forgotemail", this.forgotPwdForm.value.email);
            this._commService.presentsToast("success", response.message);

            this.router.navigate(["/otp"]);
          },
          (err: any) => {
            this._commService.presentsToast("error", err.error.message);
          }
        );
    }
  }
}
