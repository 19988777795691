import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "./modules/material.module";

import { FixDecimalPlacePipe } from "./pipes/fix-decimal-place.pipe";
import { LuxonDatePipe } from "./pipes/luxon-date.pipe";

const components = [
  FixDecimalPlacePipe,
  // AudioAlertComponent,
  LuxonDatePipe,
];

const modules = [
  CommonModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components, ...modules],
})
export class SharedModule {}
