import { MapsAPILoader } from "@agm/core";
import { Component } from "@angular/core";
// import { AccountsMutation } from "@shared/graphql/mutations/accounts.mutations";
import { AuthService } from "@shared/services/auth.service";
// import { DriverService } from "@shared/services/driver.service";
// import { KeyCloakAuthService } from "@shared/services/keycloak-auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private _unsubscribeAll = new Subject();
  showChat: any = true;
  constructor(
    private authService: AuthService,
    private mapsApiLoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.mapsApiLoader.load();
  }

  hideChaticon() {
    this.showChat = false;
  }
  showChaticon() {
    this.showChat = true;
  }
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
