import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

type TalinoCloudApiResponse<T> = {
  api_version: string;
  author: string;
  response: string;
  data: T;
};

type AuthDetails = {
  fullname: string;
  merchant_id: string;
  mobile: string;
  userid: string;
  username: string;
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser$: BehaviorSubject<any>;

  constructor(private httpClient: HttpClient) {
    this.currentUser$ = new BehaviorSubject(null);
  }

  async logOut() {
    localStorage.clear();
  }

  get isLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem("fah_backoffice_user"));
  }
}
